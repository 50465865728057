import { Link } from 'react-router-dom';
import { NavProps } from './Nav.Interface';

import OpenMenu from '../../../assets/Open-Menu.svg';
import CloseMenu from '../../../assets/Close-Menu.svg';

import NavPaths from './Nav.Paths';

import './Nav.css';

const Nav = ({ hamburgerOpen, handleHamburgerClick }: NavProps) => {
  const NavLinks = (className = '') => {
    const linkStyles = 'text-lg text-slate-50 hover:text-orange-500';
    return (
      <>
        {NavPaths.map((path, index) => (
          <Link key={`nav-link-${index}`} className={`${linkStyles} ${className}`} to={path.routeUrl}>
            {path.routeLabel}
          </Link>
          )
        )}
      </>
    );
  };

  const renderHamburgerIcon = (state: boolean) => {
    return (
      <img src={!state ? OpenMenu : CloseMenu} alt="Hamburger Menu Button" />
    );
  };

  const renderHamburgerMenu = () => {
    return hamburgerOpen ? (
      <div onClick={handleHamburgerClick} className="hamburger-overlay z-10">
        <div className="hamburger flex flex-col p-3 rounded bg-gray-700 border-solid border-2 border-orange-500">
          {NavLinks('my-1')}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className="w-5/12 flex justify-end">
      <nav className="w-full hidden md:flex justify-around my-auto text-slate-50">
        {NavLinks()}
      </nav>
      <button
        onClick={handleHamburgerClick}
        className="block md:hidden text-slate-50"
      >
        {renderHamburgerIcon(hamburgerOpen)}
      </button>
      {renderHamburgerMenu()}
    </div>
  );
};

export default Nav;
