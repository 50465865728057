const Routes = [
  {
    routeUrl: '/',
    routeLabel: 'Home'
  },
  {
    routeUrl: '/info',
    routeLabel: 'Info'
  },
  {
    routeUrl: '/ontact',
    routeLabel: 'Contact'
  },
  {
    routeUrl: '/allery',
    routeLabel: 'Gallery'
  },
];

export default Routes;