import { useEffect, useState } from 'react';

const Grid = () => {
  const [activeItem, setActiveItem] = useState<string>();
  useEffect(() => {
    console.log(activeItem);
  }, [activeItem]);

  const gridItems = [
    'ABS', 'PLA', 'PETG', 'TPU', 'PVA'
  ];

  const gridItemClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    const item = e.currentTarget.dataset['item'];
    if (item === activeItem) setActiveItem('');
    else setActiveItem(item);
    
  };

  const renderGridItems = () => {
    const activeItemClasses = 'transition-all duration-150 ease-linear';

    return gridItems.map((item, index) => {
      const isActiveItem = activeItem === item;
      return (
        <>
          <div onClick={gridItemClickHandler} data-item={item} className={`overflow-hidden transition-all duration-250 ease-linear auto-rows-auto bg-gray-700 text-slate-50 flex flex-col justify-center ${isActiveItem ? activeItemClasses : ''}`} key={`grid-item-${index}`}>
            {item}
            {isActiveItem ? (
              <div className="">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vulputate quis neque et rhoncus.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vulputate quis neque et rhoncus.</p>
              </div>
            ): null}
          </div>
        </>
      );
    });
  };

  return (
    <div className="grid grid-cols-1 text-center gap-2">
      {renderGridItems()}
    </div>
  );
};

export default Grid;