import { FooterProps } from './Footer.Interface';

const Footer = ({children}: FooterProps) => {
  return (
    <footer className="text-center bg-gray-700 h-16 min-h-min p-2 text-slate-50">
      {children}
    </footer>
  );
};

export default Footer;