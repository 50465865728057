import { useState } from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
import Logo from '../../assets/3D-Dudes.svg';

import Nav from './Nav/Nav';

const Header = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const handleHamburgerClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  return (
    <header className="h-16 flex justify-between border-solid border-b-4 border-orange-500 bg-gray-700 px-4">
      <Link className="flex" to="/">
        <img
          src={Logo}
          alt="3D Dudes Prototyping Logo"
          className="w-32 text-slate-50 my-auto"
        />
      </Link>
      <Nav
        hamburgerOpen={hamburgerOpen}
        handleHamburgerClick={handleHamburgerClick}
      />
    </header>
  );
};

export default Header;
