import BannerImage from '../../../assets/BannerImage.avif';

const HeroBanner = () => {
  return (
    <div className="h-80 md:h-1/2 relative">
      <img className="h-full w-full" src={BannerImage} alt="" />
      <div className="absolute bottom-0 bg-zinc-600/75 w-full p-2 text-lg text-orange-500">
        <p className="w-10/12 text-center mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vulputate quis neque et rhoncus.
        </p>
      </div>
    </div>
  );
};

export default HeroBanner;