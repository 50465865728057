import { LayoutProps } from './Layout.Interface';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './Layout.css';
import Paragraph from '../UI/Paragraph/Paragraph';

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="layout md:container mx-auto min-h-full flex flex-col">
      <Header />
      <main className="h-full">{children}</main>
      <Footer>
      < Paragraph>3D Dudes reserves all rights to the assets used</Paragraph>
      </Footer>
    </div>
  );
};

export default Layout;
