import { ContainerProps } from './Continer.Interface';

const Container = (props: ContainerProps) => {
 return (
  <div className="px-4 py-2 bg-gray-400">
    {props.children}
  </div>
 );
};

export default Container;