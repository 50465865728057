import Layout from '../../components/Layout/Layout';
import HeroBanner from '../../components/UI/HeroBanner/HeroBanner';
import Container from '../../components/UI/Container/Container';
import Heading from '../../components/UI/Heading/Heading';
import Paragraph from '../../components/UI/Paragraph/Paragraph';
import Image from '../../components/UI/Image/Image';

import BannerImage from '../../assets/BannerImage.avif';
import Grid from '../../components/UI/Grid/Grid';
const Home = () => {
  return (
    <Layout>
      <HeroBanner />
      <Container>
        <Heading>Hello world</Heading>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vulputate quis
          neque et rhoncus. Aenean faucibus facilisis dui sit amet blandit. Vivamus bibendum,
          odio eu fermentum convallis, tortor diam ornare arcu, vel ornare lorem sapien et lacus.
          Sed odio velit, interdum eget turpis non, porta tempor ante.
        </Paragraph>
        <Image src={BannerImage} alt='test' />
      </Container>
      <Container>
        <Heading>Materials</Heading>
        <Paragraph>
          List of materials available
        </Paragraph>
        <Grid />
      </Container>
    </Layout>
  );
};

export default Home;
